@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');

body{
  margin: 0;
}

#dark{
  background-color: #242424;
  color: white;
}

.landing{
  height: 100vh;
}
.start{
  max-width: 1240px;
  display: flex;
  margin: 0 auto;
  height: 10%;
}

span{
  color: #1493DC;
}

nav{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
}

.logo{
  width: 4rem;
  margin: 0 0.75rem;
}

#dark .logo{
  filter: invert(1);
}

#dark .headshot{
  filter: invert(1);
}

.name{
  display: flex;
}

.introContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
}

.intro{
  position: relative;
  display: flex;
  max-width: 1200px;
  gap: 3rem;
}


h1{
  width:100%; 
  font-family: 'Josefin Sans', sans-serif;
}

h2{
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 200;
  font-size: 1rem;
  text-transform: uppercase;
}

p{
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
}

.title{
  margin: 0;
  font-size: 4.5rem;
  letter-spacing: .1em;
}


.button{
  display:flex;
}

.headshot{
  width: 100%;
  display: flex;
  border-radius: 100%;
  align-items: center;
  transition: all 600ms 500ms;
}

.pic {
  flex: 1 1 0;
}

.main_text{
  flex: 1 1 0;
  padding: 1.5rem;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  gap: 4rem;
}

.icon{
  width: 60px;
  height: 60px;
  color: black;
  transition: transform 0.2s ease-in-out;
}

.icon:hover{
  transform: translateY(-5px);
}

#dark .icon{
  color: white;
}

.contact{
  display: flex;
  width: 25%;
  min-width: 170px;
  justify-content: space-between;
}

.projects{
  position: relative;
  display: flex;
  margin: 4.5rem auto;  
  max-width: 1200px;
  padding: 0 3rem;
  align-items: center;
  flex-direction: column;
  gap: 160px;
}

.projects > div:nth-child(even) {
  flex-direction: row-reverse;
}

.tagline{
  line-height: 1.5;
  font-size: 1.6rem;
}

.low-highlight {
  background:linear-gradient(180deg, transparent 60%, #FFCB05 60%);
  color: black;
}

#dark .low-highlight{
  color: white;
}

.website{
  display: flex;
  flex-direction: column;
}

.titleproject{
  margin-bottom: 0;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
}

.container{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4rem;
}


.textcontainer{
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  margin: 0;
}

.imgcontainer{
  flex: 1 1 0;
  margin-top: 0;
}

#dark .imgcontainer{
  background-color: black;
  border-radius: 1.25rem;
}

.projectimg{
  height:100%; 
  width:100%;
  box-shadow: 0px 0px 9px black;
  border-radius: 1.25rem;
}

.toolcontainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
}

.toolrow{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
}

.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
}


@media screen and (max-width: 850px) {
  .pic{
    display: none;
  }

  .main_text{
    justify-content: center;
  }
  .title{
    font-size: 3.5rem;
    text-align: center;
  }

  .tagline{
    font-size: 1.3rem;
    text-align: center;
  }

  .contactContainer{
    display: flex;
    justify-content: center;
  }

  .icon{
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 900px){
  .container{
    flex-direction: column;
  }

  .projects > div:nth-child(even) {
    flex-direction: column !important;
  }
}

@media screen and (max-height: 450px){
  .main_text{
    gap: 0rem;
  }
}